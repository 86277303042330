import Keycloak, { KeycloakInitOptions, KeycloakLogoutOptions } from 'keycloak-js';

export const appendParentOriginIntoUrl = (url: string, loggingEnabled: boolean): string => {
  var isInIframe = window === window.parent ? false : true;

  if (isInIframe) {
    try {
      //  window.opener.location.href,  window.opener.location.href throw an error due to security reasons

      const documentReferrer = document.referrer;
      const documentReferrerHost = documentReferrer.split('/')[2];

      if (loggingEnabled) {
        console.info('DOCUMENT REFERRER: ', documentReferrer);
        console.info('DOCUMENT REFERRER HOST: ', documentReferrerHost);
      }

      const firstAncestorOrigin = window.location.ancestorOrigins?.[0];
      const firstAncestorOriginHost = firstAncestorOrigin?.split('/')?.[2];

      if (loggingEnabled) {
        console.info('ANCESTOR ORIGIN: ', firstAncestorOrigin);
        console.info('ANCESTOR ORIGIN HOST: ', firstAncestorOriginHost);
      }

      const parentHost =
        window.location !== window.parent.location
          ? documentReferrerHost ?? firstAncestorOriginHost
          : window.location.hostname;

      if (loggingEnabled) {
        console.info('WINK PARENT ORIGIN HOST: ', parentHost);
      }

      url = url + '&wink-parent-origin=' + encodeURIComponent(parentHost);
    } catch (error) {
      console.error('Unable to add wink parent origin query param due to error: ', error);
    }
  }

  return url;
};

export const defaultKeycloakInitConfig: KeycloakInitOptions = {
  onLoad: 'check-sso',
  pkceMethod: 'S256',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  checkLoginIframe: true,
  silentCheckSsoFallback: true,
  enableLogging: true,
};

export const getUrl = ({ authServerUrl, realm }: Keycloak) => {
  if (!authServerUrl) {
    return null;
  }

  if (authServerUrl.charAt(authServerUrl.length - 1) === '/') {
    return authServerUrl + 'realms/' + encodeURIComponent(realm!);
  }

  return authServerUrl + '/realms/' + encodeURIComponent(realm!);
};

export const getRedirectUri = (keycloak: Keycloak, options?: KeycloakLogoutOptions | undefined) => {
  return options?.redirectUri
    ? options.redirectUri
    : keycloak.redirectUri
    ? keycloak.redirectUri
    : location.href;
};

export const getLogoutUrl = (keycloak: Keycloak, options?: KeycloakLogoutOptions): string => {
  const { idToken, clientId } = keycloak;

  return (
    getUrl(keycloak) +
    '/winklogout' +
    '?post_logout_redirect_uri=' +
    encodeURIComponent(getRedirectUri(keycloak, options)) +
    '&id_token_hint=' +
    encodeURIComponent(idToken!) +
    '&client_id=' +
    encodeURIComponent(clientId!)
  );
};

export const getLoginUrl = ({ createLoginUrl }: Keycloak, loggingEnabled: boolean): string => {
  const loginUrl = appendParentOriginIntoUrl(createLoginUrl(), loggingEnabled);

  return loginUrl;
};

const changeUrlWithoutReloading = (url: string) => {
  const stateObj = { page: url };
  history.pushState(stateObj, '', url);
};

export const getUrlParamValue = (paramName: string) => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  return params.get(paramName);
};

export const removeUrlParam = (paramName: string) => {
  const originalUrl = window.location;
  const updatedUrl = removeParameterFromUrl(originalUrl.href, paramName);
  changeUrlWithoutReloading(updatedUrl);
};

const removeParameterFromUrl = (url: string, parameter: string) => {
  const urlObj = new URL(url);
  const searchParams = urlObj.searchParams;
  searchParams.delete(parameter);
  urlObj.search = searchParams.toString();
  const updatedUrl = urlObj.toString();
  return updatedUrl;
};

export const winkSetCookie = (name: string, value: string, expiration = 1) => {
  const d = new Date();
  d.setTime(d.getTime() + expiration * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

export const winkGetCookie = (cname: string) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const winkEraseCookie = (name: string) => {
  document.cookie = name + '=; Max-Age=-99999999;';
};
