import OriginalKeycloak, {
  KeycloakConfig,
  KeycloakError,
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
} from 'keycloak-js';

import {
  defaultKeycloakInitConfig,
  getLogoutUrl,
  appendParentOriginIntoUrl,
  getUrlParamValue,
  removeUrlParam,
  winkGetCookie,
  winkEraseCookie,
  winkSetCookie,
} from './helper';

type WinkInitParams = {
  onFailure?(error: unknown): void;
  onSuccess?(): void | Promise<void>;
};

type WinkKeycloakConfig = KeycloakConfig & {
  onAuthErrorFailure?(error: KeycloakError): void;
  loggingEnabled?: boolean;
};

type WinkLoginParams = KeycloakLoginOptions & {
  onFailure?(error: unknown): void;
};

type WinkLogoutParams = KeycloakLogoutOptions & {
  onFailure?(error: unknown): void;
};

class CustomKeycloak extends OriginalKeycloak {
  onAuthErrorFailure?: (error: KeycloakError) => void;
  loggingEnabled: boolean = true;

  constructor(config: WinkKeycloakConfig) {
    super(config);
    this.onAuthErrorFailure = config.onAuthErrorFailure;
    this.loggingEnabled = config.loggingEnabled ?? true;
  }

  onReady(authenticated?: boolean | undefined): void {
    if (this.loggingEnabled) {
      console.debug('Keycloak client ready.');
      console.debug('Keycloak client authenticated: ', authenticated);
    }
  }

  onAuthError(errorData: KeycloakError): void {
    this.onAuthErrorFailure?.(errorData);

    if (this.loggingEnabled) {
      console.error('Keycloak client authentication error: ', errorData);
    }
  }

  // NOTE custom logout url
  createLogoutUrl = (options?: KeycloakLogoutOptions): string => {
    try {
      const logoutUrl = getLogoutUrl(this, options);

      return logoutUrl;
    } catch (error) {
      if (this.loggingEnabled) {
        console.error('Error while constructing the logout url: ', error);
      }
      throw error;
    }
  };

  winkInit = async (params?: WinkInitParams): Promise<void> => {
    const { onFailure, onSuccess } = params ?? {};
    try {
      if (this.loggingEnabled) {
        console.debug('Calling Keycloak init.');
      }
      const authenticated = await this.init(defaultKeycloakInitConfig);

      if (authenticated) {
        onSuccess?.();
        // Following line is workaround for iframe 3rd party cookie issue
        winkSetCookie('wink_id_token', this.idToken!);
      }
      // Following code is workaround for iframe 3rd party cookie issue
      else {
        // Following if is workaround for iframe 3rd party cookie issue
        if (getUrlParamValue('login_session_state') === '1') {
          this.winkLogin();
        }
      }
    } catch (error) {
      if (this.loggingEnabled) {
        console.error('Error while wink client initialization: ', error);
      }
      onFailure?.(error);
    }
  };

  winkLogin = async (params?: WinkLoginParams): Promise<void> => {
    const { onFailure } = params ?? {};
    try {
      if (this.loggingEnabled) {
        console.debug('Calling Keycloak login.');
      }

      await this.login(params);
    } catch (error) {
      if (this.loggingEnabled) {
        console.error('Error while wink client login: ', error);
      }

      onFailure?.(error);
    }
  };

  winkLogout = async (params?: WinkLogoutParams): Promise<void> => {
    const { onFailure } = params ?? {};
    try {
      if (this.loggingEnabled) {
        console.debug('Calling Keycloak logout.');
      }
      // Following code is workaround for iframe 3rd party cookie issue
      this.idToken = this.idToken ?? winkGetCookie('wink_id_token');
      winkEraseCookie('wink_id_token');
      removeUrlParam('login_session_state');
      // End of workaround

      await this.logout(params);
    } catch (error) {
      if (this.loggingEnabled) {
        console.error('Error while wink client logout: ', error);
      }

      onFailure?.(error);
    }
  };
}

//TODO custom login url workaround, can not override createLoginUrl, https://github.com/keycloak/keycloak/discussions/20989
const getWinkLoginClient = (config: WinkKeycloakConfig) => {
  const keycloakInstance = new CustomKeycloak(config);

  const originalCreateLoginUrl = keycloakInstance.createLoginUrl;

  keycloakInstance.createLoginUrl = function (options?: KeycloakLoginOptions) {
    const url = originalCreateLoginUrl.call(this, options);
    const urlWithParentOrigin = appendParentOriginIntoUrl(url, config.loggingEnabled ?? true);

    return urlWithParentOrigin;
  };

  return keycloakInstance;
};

console.info('winklogin.module.js - version 2.1.1');

//@ts-ignore
window.getWinkLoginClient = getWinkLoginClient;
